<template>
    <div class="battery-list-table">
        <el-table
            class="battery-list-table"
            :data="alarmBatteryList"
            height="100%"
        >
            <el-table-column
                prop="batteryCode"
                label="电池ID"
                width="170"
            >
                <template slot-scope="{row}">
                   <span style="cursor: pointer;" @click="serchOneBattery(row.batteryCode)"> {{ row.batteryCode }} </span>
                </template>
            </el-table-column>
            <el-table-column
                prop="batteryType"
                label="电池类型"
            >
            </el-table-column>
            <el-table-column
                prop="batteryBrand"
                label="电池品牌"
            >
            </el-table-column>
            <el-table-column
                prop="faultName"
                label="故障名称"
            >
            </el-table-column>
            <el-table-column
                prop="faultLevel"
                label="故障等级"
            >
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import './BatteryList.less'
export default {
    name: 'BatteryList',
    props: {
        alarmBatteryList: {
            type: Array,
            default: () => ([])
        }
    },
    methods: {
        serchOneBattery(batteryCode) {
            this.$emit('serchOneBattery', batteryCode)
        }
    }
}
</script>
