<template>
  <el-dialog
    class="battery-detail-modal"
    :title="fillData.sn+' 设备详情'"
    :visible="dialogVisible"
    width="60%"
    :modal-append-to-body="true"
    @close="onCloseHandel"
  >
    <div class="battery-modal-detail-content">
      <div class="data-info">
        <div class="data-item">
          <div class="data-title">设备状态:</div>
          <div class="data-content">{{fillData.ompStatus | ompStatusStr}}</div>
        </div>
        <div class="data-item">
          <div class="data-title">设备品牌:</div>
          <div class="data-content">{{fillData.producer || '-'}}</div>
        </div>
        <div class="data-item">
          <div class="data-title">业务状态:</div>
          <div class="data-content">{{fillData.currentStatus || '-'}}</div>
        </div>
        <div class="data-item">
          <div class="data-title">设备规格:</div>
          <div class="data-content">{{fillData.model || '-'}}</div>
        </div>
      </div>
      <div class="data-info">
        <div class="data-item">
          <div class="data-title">当前网点:</div>
          <div class="data-content">{{fillData.store || '-'}}</div>
        </div>
        <div class="data-item">
          <div class="data-title">联系人:</div>
          <div class="data-content">{{fillData.storeContract || '-'}}</div>
        </div>
        <div class="data-item">
          <div class="data-title">联系方式:</div>
          <div class="data-content">{{fillData.storePhone || '-'}}</div>
        </div>
      </div>
      <div class="data-info">
        <div class="data-item">
          <div class="data-title">当前用户:</div>
          <div class="data-content">{{fillData.user || '-'}}</div>
        </div>
        <div class="data-item">
          <div class="data-title">联系人:</div>
          <div class="data-content">{{fillData.contact || '-'}}</div>
        </div>
        <div class="data-item">
          <div class="data-title">联系方式:</div>
          <div class="data-content">{{fillData.phone || '-'}}</div>
        </div>
      </div>
      <div class="data-info">
        <div class="data-item">
          <div class="data-title">故障信息:</div>
          <div class="data-content">{{fillData.infoA || '-'}}</div>
        </div>
        <div class="data-item">
          <div class="data-title">离线时间:</div>
          <div class="data-content">{{fillData.faultTimeB || '-'}}</div>
        </div>
        <div class="data-item">
          <div class="data-title">低电量时间:</div>
          <div class="data-content">{{fillData.faultTimeC || '-'}}</div>
        </div>
      </div>
      <div class="data-info">
        <div class="data-item">
          <div class="data-title">故障等级:</div>
          <div class="data-content">{{fillData.faultLevelA || '-'}}</div>
        </div>
        <div class="data-item">
          <div class="data-title">持续时间:</div>
          <div class="data-content">{{fillData.intervalB || '-'}}</div>
        </div>
        <div class="data-item">
          <div class="data-title">持续等级:</div>
          <div class="data-content">{{fillData.faultLevelC || '-'}}</div>
        </div>
      </div>
      <div class="data-info">
        <div class="data-item">
          <div class="data-title">故障名称:</div>
          <div class="data-content">{{fillData.code || '-'}}</div>
        </div>
        <div class="data-item">
          <div class="data-title">处理时间:</div>
          <div class="data-content">{{fillData.dealTimeB || '-'}}</div>
        </div>
        <div class="data-item">
          <div class="data-title">持续时间:</div>
          <div class="data-content">{{fillData.intervalC || '-'}}</div>
        </div>
      </div>
      <div class="data-info">
        <div class="data-item">
          <div class="data-title">发生时间:</div>
          <div class="data-content">{{fillData.faultTimeA || '-'}}</div>
        </div>
        <div class="data-item">
          <div class="data-title"></div>
          <div class="data-content"></div>
        </div>
        <div class="data-item">
          <div class="data-title">处理时间:</div>
          <div class="data-content">{{fillData.dealTimeC || '-'}}</div>
        </div>
      </div>
      <div class="data-info">
        <div class="data-item">
          <div class="data-title">持续时间:</div>
          <div class="data-content">{{fillData.intervalA || '-'}}</div>
        </div>
      </div>
      <div class="data-info">
        <div class="data-item">
          <div class="data-title">处理时间:</div>
          <div class="data-content">{{fillData.dealTimeA || '-'}}</div>
        </div>
      </div>
      <div class="button">
        <div class="button-content" @click="gotoCloud">立即处理</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import CreateFaultModal from "../../work-order-management/fault-rescue/components/CreateFaultModal";
export default {
  name: "DetailModal",
  components: {
    CreateFaultModal,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    fillData: {
      type: Object,
      default: {}
    },
  },
  methods: {
    onCloseHandel() {
      this.$emit('close');
    },
    gotoCloud() {
      this.$router.push({
        name: "CloudControl",
        params: {
          sn: this.fillData.sn,
        },
      });
    }
    
  },
  filters: {
    ompStatusStr(status) {
      let statusStr = "-";
      if (status === 0) {
        statusStr = "正常";
      } else if (status === 1) {
        statusStr = "待处理";
      } else if (status === 2) {
        statusStr = "待回调";
      } else if (status === 3) {
        statusStr = "待售后";
      } else {
        statusStr = "-";
      }
      return statusStr;
    }
  }
};
</script>

<style lang="less">
.battery-modal-detail-content {
  padding: 0 30px;
  height: 420px;
  .data-info {
    display: flex;
    margin-top: 30px;

    .data-item {
      display: flex;
      margin-right: 50px;

      .data-title {
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        color: #999999;
        width: 80px;
      }

      .data-content {
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        color: #333;
        margin-left: 15px;
        width: 80px;
      }
    }
  }
  .button {
    width: 100%;
    height: 100px;
    margin-top: 50px;
    position: relative;

    .button-content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background: #f41b36;
      color: #fff;
      width: 100px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 5px;
    }
  }
  .fault-info {
    height: 40px;
    display: flex;
    align-content: center;
    align-items: center;
    .fault-name {
      width: 80px;
      height: 30px;
      background: #f41b36;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      text-align: center;
    }
    .online {
      background-color: #16c47b;
    }
    .preAlarm {
      background-color: #f2a745;
    }
    .alarm {
      background-color: #fe3232;
    }
    .offline {
      background-color: #aaa;
    }
    .fault-time-info {
      flex: auto;
      .alarm-time,
      .data-last-time {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        height: 20px;
        line-height: 20px;
        text-align: right;
        color: #666;
        .value {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: rgba(0, 150, 255, 1);
        }
      }
    }
  }
  .fault-name-info {
    height: 110px;
    margin-top: 30px;
    .title {
      height: 30px;
      display: flex;
      align-items: center;
      .name {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .des {
        margin-left: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
    .battery-info {
      height: 93px;
      margin-top: 10px;
      background: #f2f2f2;
      border-radius: 3px;
      .battery-info-item {
        height: 30px;
        padding-left: 10px;
        line-height: 30px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .gps-info {
    height: 100px;
    margin-top: 40px;
    .title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #222222;
    }
    .serve-site-info {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      .serve-item {
        height: 30px;
        line-height: 30px;
        .value {
          margin-left: 20px;
        }
      }
    }
  }
  .order-button {
    width: 150px;
    height: 30px;
    margin-top: 20px;
    margin-left: 180px;
    background: #0096ff;
    border-radius: 3px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
  }
}
</style>