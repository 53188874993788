<template>
    <span @click.stop="changeVisible(true)">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                :title="operateType === 'add' ? '创建故障处理工单' : '修改故障处理工单'"
                :visible.sync="visible"
                :modal-append-to-body="false"
                width="600px"
            >
                <el-form 
                    ref="faultForm"
                    label-width="100px" 
                    class="create-fault-form"
                    :model="formData"
                    :rules="rules"
                >
                    <el-form-item label="电池编号" :label-width="'150px'" prop="batteryCode">
                        <el-select 
                            :filterable="true" 
                            placeholder="电池编号" 
                            :style="{width: '315px'}" 
                            v-model="formData.batteryCode"
                            @change="batteryChange"
                        >
                            <el-option v-for="item in batteryList" :key="item.batteryCode" :label="item.batteryCode" :value="item.batteryCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="归属站点" :label-width="'150px'" prop="siteName">
                        <el-input disabled v-model="formData.siteName" placeholder="归属站点" :style="{width: '315px'}"></el-input>
                    </el-form-item>
                    <el-form-item  label="故障" :label-width="'150px'">
                        <el-select v-model="formData.faultCodes" placeholder="故障" :style="{width: '315px'}">
                            <el-option v-for="item in faultList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  label="故障等级" :label-width="'150px'">
                        <el-select v-model="formData.faultLevel" placeholder="故障等级" :style="{width: '315px'}" @change="changeHandel">
                            <el-option label="一级" :value="1"></el-option>
                            <el-option label="二级" :value="2"></el-option>
                            <el-option label="三级" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  label="联系人" :label-width="'150px'" prop="contacts">
                        <el-input v-model="formData.contacts" placeholder="联系人" :style="{width: '315px'}"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" :label-width="'150px'" prop="contactNumber">
                        <el-input v-model="formData.contactNumber"  placeholder="联系电话" :style="{width: '315px'}"></el-input>
                    </el-form-item>
                    <el-form-item label="故障描述" :label-width="'150px'" prop="faultDesc">
                        <el-input type="textarea" rows="4" v-model="formData.faultDesc"  placeholder="故障描述" :style="{width: '315px'}"></el-input>
                    </el-form-item>
                    <el-form-item label="期望接单时长(min)" :label-width="'150px'" prop="limitMinTime">
                        <el-input-number :min="0" rows="4" v-model="formData.limitMinTime"  placeholder="预计接单时间" :style="{width: '315px'}"></el-input-number>
                    </el-form-item>
                    <el-form-item label="运维人员" :label-width="'150px'">
                        <el-select placeholder="运维人员" :style="{width: '315px'}" v-model="formData.userId">
                            <el-option v-for="item in oerpaterList" :key="item.id" :label="item.userName" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="visible = false;">取 消</el-button>
                    <el-button type="primary" @click="rightNowOrder">立即派单</el-button>
                    <el-button type="primary" @click="submitHandel">新建工单</el-button>
                </span>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import './CreateFaultModal.less';
import axios from '@/utils/axios';
export default {
    name: 'CreateFaultModal',
    props: {    
        operateType: {
            type: String,
            default: 'add'
        },
        getData: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            visible: false,
            formData: {
                batteryCode: '',
                siteCode: '',
                siteName: '',
                faultCodes: '',
                faultLevel: 3,
                contacts: '',
                contactNumber: '',
                userId: '',
                faultDesc: '',
                limitMinTime: 0
            },
            rules: {
                batteryCode: [
                    { required: true, message: '请选择电池编号', trigger: 'blur' },
                ],
                contacts: [
                    { required: true, message: '请输入联系人', trigger: 'blur' },
                ],
                contactNumber: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                ],
                siteName: [
                    { required: true, message: '请填写站点信息', trigger: 'blur' },
                ],
                faultDesc: [
                    { required: true, message: '请填写故障描述', trigger: 'blur' },
                ],
                limitMinTime: [
                    { required: true, message: '请填写预计接单时间', trigger: 'blur' },
                ],
            },
            batteryList: [],
            faultList: [],
            faultMap: {},
            operationPersonList: []
        }
    },
    computed: {
        oerpaterList() {
            const result = [];
            this.operationPersonList.forEach(item => {
                if (item.siteId === this.formData.siteCode) {
                    result.push(item);
                }
            });
            return result;
        }
    },
    methods: {
        changeVisible(flag) {
            this.visible = flag;
            if (flag) {
                this.getBatteryList();
                this.getFaultList();
                this.getOperateUserList();
            }
        },
        changeHandel(value) {
            this.formData.faultNames = this.faultMap[value]
        },
        submitHandel() {
            this.$refs.faultForm.validate(valid => {
                if (valid) {
                    const params = this.formData;
                    let url = '/api/rescueJob/createRescueJob';
                    axios.post(url, { ...params, faultNames: this.faultMap[params.faultCodes] }).then(
                        rs => {
                            this.$message.success('操作成功');
                            this.visible = false;
                            this.getData();
                            this.formData = {
                                batteryCode: '',
                                siteCode: '',
                                faultCodes: 1,
                                faultLevel: 1,
                                contacts: '',
                                contactNumber: '',
                                limitMinTime: 0
                            }
                        }
                    ).catch(err => {
                        this.$message.error(err);
                    })
                } else {
                    if (!this.formData.siteCode) {
                        this.$message.info('请先为电池绑定站点');
                    }
                }
            });
        },
        getBatteryList() {
            const userinfo = localStorage.getItem('currentUser')
            const orgCode = JSON.parse(userinfo).org.orgCode;
            axios.get(`/api/battery/list`, { orgCode }).then(rs => {
                this.batteryList = rs;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        getFaultList() {
            axios.get('/api/dict/list/alarmCode', {
                alarmCode: 'guzhang',
                alarmLevel: 'jibie'
            }).then(rs => {
                this.faultList = rs;
                const faultMap = {};
                rs.forEach(element => {
                    faultMap[element.key] = element.value;
                });
                this.faultMap = faultMap;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        batteryChange(value) {
            const battery = this.batteryList.find(item => item.batteryCode === value);
            this.formData.contacts = '';
            this.formData.contactNumber = '';
            if (battery) {
                this.formData.siteCode = battery.siteId;
                this.formData.siteName = battery.siteName;
                this.getBatteryContact(battery.batteryCode);
            }
        },
        setBatterInfo(batteryCode) {
            this.formData.batteryCode =  batteryCode;
            this.getBatteryContact(batteryCode)
        },
        getOperateUserList() {
            axios.get(`/api/operationPerson/pageList/${1}/${100}`, {}).then(rs => {
                this.operationPersonList = rs.records;
            });
        },
        getBatteryContact(batteryCode) {
            axios.get(`/api/battery/contact/${batteryCode}`, { batteryCode }).then(rs => {
                const { name, tel } = rs;
                if (name && tel) {
                    this.formData.contacts = name;
                    this.formData.contactNumber = tel;
                } else {
                    this.$message.info('电池联系人信息为空');
                }
            }).catch(err => {
                this.$message.error(err);
            }); 
        },
        rightNowOrder() {
            this.$refs.faultForm.validate(valid => {
                if (valid) {
                    if (!this.formData.userId) {
                        this.$message.info('请选择运维人员');
                        return;
                    }
                    const params = this.formData;
                    let url = '/api/rescueJob/createRescueJob';
                    axios.post(url, { ...params, faultNames: this.faultMap[params.faultCodes] }).then(
                        rs => {
                            axios.post('/api/job/allocationJob', { 
                                jobCode: rs,
                                userId: this.formData.userId
                            }).then(() => {
                                this.$message.success('派单成功');
                                this.visible = false;
                                this.getData();
                            }).catch(err => {
                                this.$message.error(err);
                            });
                        }
                    ).catch(err => {
                        this.$message.error(err);
                    })
                } else {
                    if (!this.formData.siteCode) {
                        this.$message.info('请先为电池绑定站点');
                    }
                }
            });
        }
    }
}
</script>